<template>
    <transition name="fade">
        <div v-if="shown">
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <i class="bi bi-exclamation-diamond-fill fs-5 text-warning"></i>&nbsp;
                            <h5 class="modal-title">{{ title }}</h5>
                            <button type="button" class="btn-close" @click="doCancel"></button>
                        </div>
                        <div class="modal-body">
                            <slot/>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="doCancel">{{ text_cancel }}</button>
                            <button type="button" class="btn btn-primary" @click="doOK">{{ text_ok }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ConfirmDialog',
    data() {
        return {
            shown: false
        }
    },
    props: {
        title: {
            type: String,
            default: '確認'
        },
        text_ok: {
            type: String,
            default: 'OK'
        },
        text_cancel: {
            type: String,
            default: 'キャンセル'
        }
    },
    emits: [
        'cancel',
        'ok',
    ],
    methods: {
        show() {
            this.shown = true;
        },
        doCancel() {
            this.shown = false;
            this.$emit('cancel')
        },
        doOK() {
            this.shown = false;
            this.$emit('ok')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
